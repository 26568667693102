import * as React from 'react'
import { Helmet } from 'react-helmet'

// Components
import Header from 'components/shared/header'
import Footer from 'components/shared/footer'
import MobileCTA from 'components/shared/mobileCTA'
import RegistrationForm from 'components/registration/form'
import * as queryString from 'querystring'
import DownloadPopup from 'components/shared/downloadPopup'
import { useState } from 'react'
import extension from '../images/chrome-extension.png'
import { getTranslation } from 'data/dictionary'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'


const ChromeExtension = styled.img`
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    width: 50px;
  }
`
const ExtensionContainer = styled.div`

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  position: center;

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    justify-content: center;
    margin-left: 20px;
  }

  .extension-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // allign-items: center;
    row-gap: 50px;

    .extension-icon {
        cursor: pointer; 
         transform: translate(5px, -5px);
  }


    .download-extension {
    font-weight: 400;
  }

    > h6 {
          cursor: pointer;
          width: max-content;

          @media only screen and (max-width: ${SCREEN.MOBILE}) {
            width: unset;
          }
    }

  }

  .about-extension {
    transform: translateY(-25px);
    justify-content: center;

    
    > h6{
    font-size: 20px;
    font-weight: 300;

        a{
      font-size: 20px !important;
      font-weight: 300 !important;
      color: #000  !important;
      text-decoration: underline !important;
      cursor: pointer !important;
    }
  }
`

const Main = ({
                children,
                title = '',
                showFooter = true,
                hideTitlePrefix = false,
                ogImage,
              }) => {
  const [regModalIsOpen, setRegModalIsOpen] = React.useState(false)
  const [signInForm, setSignInForm] = React.useState(false)
  const [mobileCTAVisible, setMobileCTAVisible] = React.useState(true)
  const [signedIn, setSignedIn] = React.useState(false)
  const [user, setUser] = React.useState({})

  React.useEffect(() => {
    let { showLogin } = queryString.parse(window.location.search.substr(1))
    if (showLogin) {
      setRegModalIsOpen(true)
      setSignInForm(true)
    }

    if (typeof document !== undefined && typeof window !== undefined) {
      try {
        const user = JSON.parse(window.localStorage.getItem('user'))
        // if (user.role === 'ROLE_STUDENT' && user.isPaid === false && !window.location.href.includes('/student-subscription')) {
        //    window.location.href = '/student-subscription'
        //   return
        // }
        setUser(user)
        setSignedIn(typeof user.firstName !== 'undefined')
      } catch (e) {
        setUser({})
        setSignedIn(false)
      }
    }
  }, [])

  const onSignOut = () => {
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('token')
    window.location.href = '/'
}


  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>
          {'BuildUp | Problem-Based Learning School Powered by AI'}
          {/* {title} */}
        </title>
        <meta
          name='description'
          content='Problem-Based Learning School Powered by AI'
        />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <meta
          property='og:image:url'
          content={
            ogImage ||
            'https://d34rq7yialevbd.cloudfront.net/uploads/dc25f9f7-092e-416d-bd62-edbf542d231a'
          }
        />
        <meta
          property='og:title'
          content={`${hideTitlePrefix ? 'BuildUp | Problem-Based Learning School Powered by AI' : ''}`}
        />
        <meta
          property='og:description'
          content='Problem-Based Learning School Powered by AI'
        />
        {/* <link
          href="https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&family=Montserrat:wght@700&display=swap"
          rel="stylesheet"
        /> */}
        <meta name='robots' content='index,follow' data-rh='true' />
        <meta name='googlebot' content='index,follow' data-rh='true' />
      </Helmet>
      <div
        css={`
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: stretch;, 
          justify-content: space-between;
        `}
      >
        <Header
          handleModal={type => {
            setSignInForm(type === 'login')
            setRegModalIsOpen(true)
          }}
          signedIn={signedIn}
          user={user}
          signOut={onSignOut}
        />
        <div
          css={`
            padding-top: var(--header);
            flex: 1 0;
          `}
        >
          {typeof children === 'function'
            ? children({
              setRegModalIsOpen,
              regModalIsOpen,
              setMobileCTAVisible,
            })
            : children}
        </div>
        {mobileCTAVisible && (!user || Object.keys(user).length === 0) && (
          <MobileCTA handleModal={setRegModalIsOpen} />
        )}
        
          {
          title !== 'Home' ? 
         (<>
          <ExtensionContainer>
          <div className='extension-container'>
            
            <h6 className='download-extension' onClick={() => {window.location.href = '/extension'}}>
              {
                getTranslation("chrome-extension", "Download <b>BU Chrome Extension</b>", true)
              }
            </h6>
            <a className='extension-icon' href='/extension' style={{transform: title === "Program" ? "translate(5px, -38px)" : "translate(5px, -5px)"}}>
              <ChromeExtension src={extension} className='chrome-extension'></ChromeExtension>
            </a>
            </div>
            <div className="about-extension" style={{display: title === "Pathfinder Agent: AI-Powered Problem-Based Learning" ? "none" : 'flex'}}>
            <h6>{getTranslation('about-extension', "Learn about our Extension <a href='https://buildup.am/pathfinder-extension'>here.</a>", true)}</h6>
            </div>
            </ExtensionContainer>
          </>) : null
          }
        {showFooter && <Footer />}
        <RegistrationForm
          signInForm={signInForm}
          setSignInForm={setSignInForm}
          isOpen={regModalIsOpen}
          handleModal={setRegModalIsOpen}
          signIn={() => setSignedIn(true)}
        />


      </div>
    </>
  )
}

export default Main
